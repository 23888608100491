<template>
    <zw-sidebar @shown="shown"
                :title="form.type == 'eingang' ?
                    (form.id ? $t('common.form.title.editIncomingInvoice')  + ' (' + form.id + ')'  : $t('common.form.title.createIncomingInvoice')) :
                    (form.id ? $t('common.form.title.editCredit')  + ' (' + form.id + ')'  : $t('common.form.title.createCredit'))">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="3">
                        <zw-model-selector-group v-model="form.offering_id"
                                                 name="offering"
                                                 :label-prefix="labelPrefix"
                                                 model-type="offering"
                                                 size="lg"
                                                 validate="required"
                                                 :callback="offeringSelected"
                                                 :serializer="s => s.incoming_invoice_label"
                                                 :validate="{required: form.type != 'eingang'}"
                        />
                    </b-col>
                    <b-col v-if="form.type != 'eingang'" sm="3">
                        <zw-input-group v-model="form.description"
                                        name="description"
                                        type="text"
                                        :label-prefix="labelPrefix"
                                        :readonly="fieldsReadOnly"
                        ></zw-input-group>
                    </b-col>
                    <b-col v-if="form.type == 'eingang'" sm="3">
                        <zw-input-group v-model="form.number"
                                        autocomplete="on"
                                        name="number"
                                        type="text"
                                        :label-prefix="labelPrefix"
                                        :validate="{required: form.type == 'eingang'}"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="2">
                        <search-field v-model="form.customer"
                                      name="customer"
                                      :label-prefix="labelPrefix"
                                      :url="incomingInvoiceCustomerSearchUrl"
                                      validate="required"
                                      :selectRequired="false"
                        ></search-field>
                    </b-col>
                    <b-col sm="2">
                        <zw-input-group v-model="form.net_amount"
                                        name="net_amount"
                                        type="number"
                                        :label-prefix="labelPrefix"
                                        :validate="{required: form.type == 'eingang'}"
                                        :readonly="fieldsReadOnly"
                                        @change="calcBruttoFromNetto"
                                        min=0
                                        step="0.01"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="2">
                        <zw-input-group v-model="form.brutto_amount"
                                        name="brutto_amount"
                                        type="number"
                                        :label-prefix="labelPrefix"
                                        :validate="{required: form.type == 'eingang'}"
                                        :readonly="fieldsReadOnly || preview"
                                        @change="calcNettoFromBrutto"
                                        min=0
                                        step="0.01"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="3">
                        <zw-select-group v-model="form.vat"
                                         :options="getVats() | optionsVL"
                                         name="vat"
                                         :label-prefix="labelPrefix"
                                         :validate="{required: form.type == 'eingang'}"
                                         :readonly="fieldsReadOnly"
                                         @change="()=>{(form.net_amount>0 && !preview) ? calcBruttoFromNetto() : calcNettoFromBrutto()}"
                        ></zw-select-group>
                    </b-col>

                    <b-col sm="3">
                        <zw-new-date-group v-model="form.createdAt"
                                           name="createdAt"
                                           :label-prefix="labelPrefix"
                                           :readonly="readOnly"
                                           :format="format"
                                           validate="required"
                        ></zw-new-date-group>
                    </b-col>
                    <b-col sm="3">
                        <zw-new-date-group v-model="form.payment_date"
                                           name="payment_date"
                                           :label-prefix="labelPrefix"
                                           :readonly="readOnly"
                                           :format="format"
                                           validate="required"
                        ></zw-new-date-group>
                    </b-col>

                    <template v-if="form.type == 'eingang'">
                        <b-col cols="1">
                            <zw-select-group v-model="form.cash"
                                             name="cash"
                                             :label-prefix="labelPrefix"
                                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                            ></zw-select-group>
                        </b-col>
                        <b-col v-if="form.cash == 1" cols="2">
                            <zw-select-group v-model="form.cash_user"
                                             validate="required"
                                             name="cash"
                                             :label-prefix="labelPrefix"
                                             :options="getUsersList()"
                                             valueField="id"
                                             textField="fullname"
                            ></zw-select-group>
                        </b-col>
                    </template>
                </b-row>

                <b-row v-if="form.type == 'eingang'">
                    <b-col cols="3">
                        <zw-select-group v-model="form.barkonto"
                                         name="barkonto"
                                         :label-prefix="labelPrefix"
                                         :options="getBarkonto()"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="3">
                        <zw-select-group v-model="form.rechnungskonto"
                                         name="rechnungskonto"
                                         :label-prefix="labelPrefix"
                                         :options="getRechnungskonto()"
                                         valueField="title"
                                         textField="title"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="3">
                        <zw-switch-group v-model="form.is_liability"
                                         name="is_liability"
                                         :label-prefix="labelPrefix"
                        />
                    </b-col>

                    <custom-slot id="incoming_invoice_form" :base="getThis()"></custom-slot>
                </b-row>

                <b-row v-if="form.type != 'eingang'">
                    <b-form-checkbox-group
                        v-model="form.position_data"
                    >
                        <b-col sm="11" v-for="option in positions" :key="option.id">
                            <b-form-checkbox
                                :value="option.id"
                                @change="checkReadOnly"
                            >
                                {{ option.label }}
                            </b-form-checkbox>
                        </b-col>
                    </b-form-checkbox-group>
                </b-row>
                <b-row v-if="form.type == 'eingang'">
                    <b-col cols="12">
                        <zw-textarea-group v-model="form.leistung"
                                           name="leistung"
                                           :label-prefix="labelPrefix"
                        ></zw-textarea-group>
                    </b-col>
                </b-row>


                <b-row v-if="form.type == 'eingang'">
                    <b-col cols="12">
                        <zw-media v-model="form.media"
                                  type="incoming_invoice"
                                  accepted-files="application/pdf"
                                  :parent_id="form.id"
                                  preview
                                  rename
                        ></zw-media>
                    </b-col>
                </b-row>
                <b-row v-if="!readOnly" align-h="end">
                    <b-col sm="3" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-row v-if="form.id">
                    <b-col>
                        <h4>{{ $t('common.form.title.incomingInvoiceBankTransactions') }}</h4>
                        <zw-table ref="table"
                                  tableName="incoming_invoice_bank_transactions"
                                  base-table="bank_transactions"
                                  :items="myProvider"
                                  :customSource="getThis()"
                                  :fields="fields"
                                  :filter="filter"
                                  :selected="selected"
                                  :selectedSource="selectedSource"
                                  :actions-list="getActions()"
                        >
                            <template v-slot:top-actions>
                                <b-card
                                    border-variant="info"
                                    align="center"
                                    class="zero_padding_card_body mt-2"
                                >
                                    <b-card-text>
                                        <b-row>
                                            <b-col cols="6">
                                                <b-row>
                                                    <b-col class="text-right" cols="6">
                                                        {{ $t('columns.offering.netto') }}:
                                                    </b-col>
                                                    <b-col class="text-left" cols="6">
                                                        {{ form.net_amount | priceFormat }} &euro;
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="text-right" cols="6">
                                                        {{ $t('columns.offering.brutto') }}:
                                                    </b-col>
                                                    <b-col class="text-left" cols="6">
                                                        {{ form.brutto_amount | priceFormat }} &euro;
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-row>
                                                    <b-col class="text-right" cols="6">
                                                        {{ $t('columns.offering.paid') }}:
                                                    </b-col>
                                                    <b-col class="text-left" cols="6">
                                                        {{ form.paid_sum | priceFormat }} &euro;
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="text-right" cols="6">
                                                        {{ $t('columns.offering.diff') }}:
                                                    </b-col>
                                                    <b-col class="text-left" cols="6"
                                                           :class="((form.brutto_amount - form.paid_sum) > 0) ? 'text-danger':'text-success'">
                                                        {{ (form.brutto_amount - form.paid_sum) | priceFormat }} &euro;
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>

                                    </b-card-text>
                                </b-card>
                            </template>

                            <template #cell(actions)="row">
                                <b-dropdown class="mb-1"
                                            left
                                            variant="primary"
                                            size="sm"
                                >
                                    <template #button-content>
                                        {{ $t('common.label.actions') }}
                                    </template>
                                    <b-dropdown-item
                                        @click="unlink(row.item.id)"
                                    >
                                        {{ $t('bank.actions.unlink') }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </zw-table>

                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwMedia from "../../components/ZwMedia";
import ZwNewDateGroup from "../../components/ZwNewDateGroup";
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    components: {ZwModelSelectorGroup, ZwMedia, ZwNewDateGroup},
    mixins: [commonTable, commonSave],

    data() {
        return {
            loading: true,
            labelPrefix: 'incoming_invoice.label.',
            offeringSearchUrl: window.apiUrl + '/offering/search',
            payload: {},
            callback: null,
            readOnly: false,
            defaultForm: {
                vat: '19',
                cash: 0,
            },
            form: {},
            preview: false,
            positions: [],
            fieldsReadOnly: false,
            saveFilters: false,
            format: 'DD.MM.YYYY',
            incomingInvoiceCustomerSearchUrl: window.apiUrl + '/incoming-invoices/customer/search',
            tableUniqKey: 'incoming-invoices',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getVats', 'getBarkonto', 'getRechnungskonto']),
        ...mapGetters('CommonTable', ['getCommonTable']),
        ...mapGetters('IncomingInvoices', ['getIncomingInvoice']),
        ...mapGetters('Users', ['getUsersList']),
        getThis() {
            return this
        },
        refreshTable() {
            this.shown()
            this.selected = []
            this.selectedSource = 'selected'
            this.$refs.table.refresh()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxBaseParams() {
            return {
                'url': '/incoming-invoices/' + this.form.id + '/bank-transactions'
            }
        },
        unlink(id) {
            this.$store.dispatch('BankTransfers/unlinkDebit', {
                id: id,
                incoming_invoice_id: this.form.id
            }).finally(() => {
                this.refreshTable()
            })
        },
        getActions() {
            return [
                {
                    title: this.$t('bank.button.assign_bank_transfer'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('assign-bank-transfer-modal', {
                            'incoming_invoice_id': this.form.id
                        }, this.refreshTable)
                    }
                },
            ]
        },
        shown() {
            const usersList = this.$store.dispatch('Users/fetchUsersList')
            if (this.payload.preview) {
                this.preview = true
                this.positions = [];
                this.form = {
                    ...JSON.parse(JSON.stringify(this.defaultForm)),
                    ...JSON.parse(JSON.stringify(this.payload.preview))
                }
                this.checkReadOnly();
                this.$refs['observer'].reset()
                this.loading = false
            } else {
                this.$store.dispatch('IncomingInvoices/fetchIncomingInvoice', {
                    id: this.payload.id,
                    type: this.payload.type,
                })
                    .then((data) => {
                        this.positions = [];
                        this.form = {
                            ...JSON.parse(JSON.stringify(this.defaultForm)),
                            ...JSON.parse(JSON.stringify(this.getIncomingInvoice()))
                        }
                        this.checkReadOnly();
                        this.$refs['observer'].reset()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        offeringSelected(offering) {
            this.positions = [];
            this.form.offering_id = offering.id;
            this.positions = offering.positions;
        },
        calcBruttoFromNetto(event) {
            if (!this.preview) {
                let vat = parseFloat(this.form.vat)
                let netto = parseFloat(this.form.net_amount)
                let brutto = (((vat + 100) / 100).toFixed(2) * netto).toFixed(2);

                this.form.brutto_amount = brutto
                this.form = {
                    ...JSON.parse(JSON.stringify(this.form)),
                }
            }
        },
        calcNettoFromBrutto() {
            let vat = parseFloat(this.form.vat)

            let brutto = parseFloat(this.form.brutto_amount)
            let netto = (brutto / ((vat + 100) / 100).toFixed(2)).toFixed(2);

            this.form.net_amount = netto
            this.form = {
                ...JSON.parse(JSON.stringify(this.form)),
            }
        },
        checkReadOnly() {
            this.fieldsReadOnly = false;
            if (this.payload.type != 'eingang') {
                if (this.form.position_data && this.form.position_data.length > 0) {
                    this.fieldsReadOnly = true;
                }
            }
            if (typeof this.form.type == "undefined") {
                this.form.type = this.payload.type;
            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('IncomingInvoices/saveIncomingInvoice', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>
<style>
.row.files-wrapper.pt-2.pb-2.download {
    margin: 0px 0px 10px 0px;
}
</style>